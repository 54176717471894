import {
  BiArea,
  BiSolidBed,
  BiSolidCarGarage,
  BiSolidKey,
} from "react-icons/bi";
import { RiLoader2Line, RiMapPin2Fill } from "react-icons/ri";
import { Button } from "../components/Button";
import { api } from "../api";
import { CondominiumsList } from "../condominiumsList.type";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import QueryString from "qs";
import { Pagination, SelectPicker } from "rsuite";
import { Helmet } from "react-helmet";

let initialCondominiums: CondominiumsList;

//tipagem das variaveis para receber do server

interface Cities {
  city: string;
  city_id: number;
}

let infoCities: Cities[];

export function Launch() {
  const [condominiumsList, setCondominiumsList] = useState(initialCondominiums);

  //variavel para receber do server
  const [infoCitiesList, setInfoCitiesList] = useState(infoCities);

  const [isLoading, setIsLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const initalPage = searchParams.get("page")
    ? Number(searchParams.get("page"))
    : 1;

  const initialCity = searchParams.get("city")
    ? String(searchParams.get("city"))
    : "";

  //cidade selecionada
  const [citiesList, setCitiesList] = useState<String>(initialCity);

  const [currentPage, setCurrentPage] = useState(initalPage);

  const [queryCitiesURL, setqueryCitiesURL] = useState("");

  const query = QueryString;

  const finalPageQuery = query.stringify(
    { page: currentPage === 1 ? undefined : currentPage },
    { indices: false }
  );

  const finalCityQuery = query.stringify(
    { city: citiesList === "" ? undefined : citiesList },
    { indices: false }
  );

  const tagPickerWidthNeighborhood = window.innerWidth > 768 ? 335 : 230;

  const getCondominiums = async () => {
    setIsLoading(true);

    const page = finalPageQuery ? `&${finalPageQuery}` : "";

    const city = finalCityQuery ? `&${finalCityQuery}` : "";

    setSearchParams(page);

    const replaceQuery = page === "" ? "/lancamentos" : "/lancamentos?";
    window.history.replaceState(null, "", replaceQuery + page + city);

    const data = await api.get(`/condominiums?` + page + city);
    return setCondominiumsList(data.data);
  };

  async function getInfoData() {
    const data = await api.get("/info-condominiums");
    return setInfoCitiesList(data.data);
  }

  useEffect(() => {
    getInfoData();
    getCondominiums().then(() => {
      setIsLoading(false);
    });
    console.log(citiesList);
  }, [currentPage, citiesList]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [finalPageQuery]);

  return (
    <>
      {/* <Helmet>
        <meta name="description" content="Lançamentos" />
      </Helmet> */}
      <section>
        <h2 className="pt-36 pb-7 bg-[#F9F9F9] text-center">Lançamentos</h2>
      </section>
      {isLoading ? (
        <div className="flex items-center justify-center mt-[4rem] mb-20 ">
          <RiLoader2Line
            size={80}
            color="#b23437"
            className="animate-[spin_5s_linear_infinite]"
          />
        </div>
      ) : (
        <>
          <div className="flex flex-col items-center justify-center mt-6 ml-[-10px] space-y-4 ">
            <SelectPicker
              className="home-picker"
              style={{
                width: tagPickerWidthNeighborhood,
                float: "right",
              }}
              menuStyle={{
                width: tagPickerWidthNeighborhood,
                float: "right",
                zIndex: 100,
              }}
              onClean={() => {
                setCitiesList("");
                setqueryCitiesURL("");
              }}
              searchable={false}
              labelKey="city"
              valueKey="city_id"
              data={infoCitiesList}
              placeholder="Cidade"
              loading={isLoading}
              onChange={(item) => {
                setCitiesList(String(item));
              }}
              defaultValue={Number(citiesList)}
            />
            <span>
              Página {currentPage} de{" "}
              {Math.floor(condominiumsList?.numberResults / 10)}
            </span>

            <Pagination
              className="justify-center"
              prev
              next
              first
              last
              size="xs"
              limit={12}
              total={condominiumsList?.numberResults}
              activePage={currentPage}
              onChangePage={setCurrentPage}
              maxButtons={6}
            />
          </div>
          <section className="grid grid-cols-2 mx-[15%] gap-10 py-10 max-[1500px]:mx-[35%] max-[1500px]:grid-cols-1 max-md:ml-7 min-[2200px]:mx-[25%]">
            {condominiumsList?.condominiums.map((item) => {
              return (
                <div className="flex items-center max-h-96">
                  <div className="relative max-w-md">
                    <img
                      loading="lazy"
                      src={item?.images[0]?.url}
                      className="relative z-0 max-h-96 min-h-[24rem] min-w-[18rem] max-w-[18rem] object-cover rounded-xl max-md:max-w-[12rem] max-md:min-w-[12rem] max-md:max-h-72 max-md:min-h-[18rem]"
                    />
                    {item.labels.length === 1 ? (
                      <>
                        <div>
                          <button className="bg-[#DAA14A] top-4 absolute text-xs pointer-events-none w-32 rounded-r-xl text-white pr-4 py-2 text-right z-30">
                            {item?.labels[0]}
                          </button>
                        </div>
                      </>
                    ) : null}
                  </div>
                  <div className="max-md:text-xs">
                    <div className="p-12 space-y-3 border-2 border-[#EBEBEB] border-solid rounded-xl ml-[-10px] overflow-hidden max-md:p-7  max-md:ml-[-7px]">
                      <h2 className="text-[#b23437] leading-tight text-xl font-extrabold max-md:text-base">
                        {item?.name}
                      </h2>
                      <br />
                      <span className="font-semibold">
                        {item?.real_state_developer_name}
                      </span>
                      <span className="flex items-center gap-1 space-y-1">
                        <BiSolidKey /> {item?.available_properties} unidades
                        disponíveis
                      </span>

                      <span className="flex items-center gap-1 space-y-1 font-semibold">
                        <RiMapPin2Fill /> {item?.address_neighborhood}
                      </span>
                    </div>
                    <div className="flex justify-center ml-[-10px] mt-[-8%]">
                      <Button
                        label="Saiba mais"
                        link={`/lancamentos/${item?.id}`}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
            {/* <div className="flex items-center max-h-96">
            <div>
              <img
                src="https://loremflickr.com/758/1024"
                className="relative z-0 max-h-96 rounded-xl"
              />
            </div>
            <div>
              <div className="p-12 space-y-3 border-2 border-[#EBEBEB] border-solid rounded-xl ml-[-10px] overflow-hidden">
                <h2 className="text-[#b23437]">Sun Haus</h2>
                <span className="font-semibold">Dimak Construtora</span>
                <div className="space-y-1">
                  <span className="flex items-center gap-1">
                    <BiArea /> De 108 a 241,82m²
                  </span>
                  <span className="flex items-center gap-1">
                    <BiSolidBed /> 3 dormitórios
                  </span>
                  <span className="flex items-center gap-1">
                    <BiSolidCarGarage /> 1 e 2 vagas de garagem
                  </span>
                </div>
                <span className="flex items-center font-semibold">
                  <RiMapPin2Fill /> Bairro Predial
                </span>
              </div>
              <div className="flex justify-center ml-[-10px] mt-[-10%]">
                <Button label="Saiba mais" link="/lancamentos" />
              </div>
            </div>
          </div>
          <div className="flex items-center max-h-96">
            <div>
              <img
                src="https://loremflickr.com/758/1024"
                className="relative z-0 max-h-96 rounded-xl"
              />
            </div>
            <div>
              <div className="p-12 space-y-3 border-2 border-[#EBEBEB] border-solid rounded-xl ml-[-10px] overflow-hidden">
                <h2 className="text-[#b23437]">Sun Haus</h2>
                <span className="font-semibold">Dimak Construtora</span>
                <div className="space-y-1">
                  <span className="flex items-center gap-1">
                    <BiArea /> De 108 a 241,82m²
                  </span>
                  <span className="flex items-center gap-1">
                    <BiSolidBed /> 3 dormitórios
                  </span>
                  <span className="flex items-center gap-1">
                    <BiSolidCarGarage /> 1 e 2 vagas de garagem
                  </span>
                </div>
                <span className="flex items-center font-semibold">
                  <RiMapPin2Fill /> Bairro Predial
                </span>
              </div>
              <div className="flex justify-center ml-[-10px] mt-[-10%]">
                <Button label="Saiba mais" link="/lancamentos" />
              </div>
            </div>
          </div> */}
          </section>
          <div className="flex flex-col items-center justify-center my-6 ml-[-10px] space-y-4">
            <span>
              Página {currentPage} de{" "}
              {Math.round(condominiumsList?.numberResults / 12) + 1}
            </span>

            <Pagination
              className="justify-center"
              prev
              next
              first
              last
              size="xs"
              limit={12}
              total={condominiumsList?.numberResults}
              activePage={currentPage}
              onChangePage={setCurrentPage}
              maxButtons={6}
            />
          </div>
        </>
      )}
    </>
  );
}
